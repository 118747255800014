import { useNavigate } from 'react-router-dom';
import './NotFound.css';

export default function NotFound() {
  const navigate = useNavigate();
  function goBackClick() {
    navigate(-1);
  }
  return (
    <div className='not-found__container'>
      <h1 className='not-found__title'>404</h1>
      <p className='not-found__text'>Страница не найдена</p>
      <button className='not-found__link link' onClick={goBackClick}>
        Назад
      </button>
    </div>
  );
}
