import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AppContext } from '../../contexts/AppContext';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { useFormWithValidation } from '../../hooks/useForm';
import { mainApi } from '../../utils/MainApi';

import './Profile.css';

export default function Profile() {
  const [isLoading, setIsLoading] = React.useState(false); // состояние выполнение запроса
  const { values, handleChange, errors, isValid, setValues } =
    useFormWithValidation();
  const { name, email } = values;
  const [isMessageApi, setIsMessageApi] = React.useState('');
  const [isEditForm, setIsEditForm] = React.useState();
  const { isCurrentUser, setCurrentUser } = React.useContext(CurrentUserContext);

  const { setIsLoggedIn, setIsInitialMovies, setInputSearch } = React.useContext(AppContext);

  let navigate = useNavigate(); // навигация в react v6


  //задаем начальные значения в форму
  React.useEffect(() => {
    setValues({ name: isCurrentUser.name, email: isCurrentUser.email});
  }, [isCurrentUser, setValues]);
  // блокируем кнопку редактировать
  React.useEffect(() => {
    if (name === isCurrentUser.name && email === isCurrentUser.email) {
      setIsEditForm(false);
    } else {
      setIsEditForm(true);
    }
  }, [isCurrentUser, email, name, isEditForm]);

  function handleEditProfile(evt) {
    evt.preventDefault();
    setIsLoading(true);
    mainApi
      .updateProfile({
        name,
        email,
      })
      .then((res) => {
        setIsMessageApi('Профиль обновлен');
        setCurrentUser(res);
      })
      .catch((err) => {
        setIsMessageApi(`Ошибка: ${err.message}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleLogOutUser() {
    mainApi
      .logout()
      .then(() => {
        setCurrentUser({});
        setIsInitialMovies([]);
        setIsLoggedIn(false);
        setInputSearch();
        localStorage.clear();
        navigate('/');
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <div className='profile'>
        <div className='profile__container'>
          <h1 className='profile__title'>Привет, {values.name}!</h1>
          <form className='profile__form' name='form__profile'>
            <label className='profile__form-label'>
              Имя
              <input
                required
                onChange={handleChange}
                className='profile__form-input'
                type='text'
                name='name'
                value={values.name || ''}
                pattern='^[А-Яа-яЁёa-zA-Z\-\s]+$'
              />
            </label>
            <p className='profile__form-text-error'>{errors.name || ''}</p>
            <label className='profile__form-label'>
              E-mail
              <input
                required
                onChange={handleChange}
                className='profile__form-input'
                type='email'
                name='email'
                value={values.email || ''}
                pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
              />
            </label>
            <p className='profile__form-text-error'>{errors.email || ''}</p>
            <p className='profile__text-api-error'>{isMessageApi}</p>
            <button
              type='button'
              disabled={(!isEditForm ?? !isValid) || isLoading}
              onClick={handleEditProfile}
              className='profile__form-button-submit link'
            >
              {!isLoading ? 'Редактировать' : 'Редактирование...'}
            </button>
          </form>
          <button className='profile__button link' onClick={handleLogOutUser}>
            Выйти из аккаунта
          </button>
        </div>
      </div>
    </>
  );
}
