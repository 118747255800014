export default function filterMovies(movies, value) {
    const search = function (movie) {
        //проверяем наличие ключей
        if (movie.nameRU && movie.nameEN) {
          const movieNameRu = movie.nameRU.toLowerCase();
          const movieNameEN = movie.nameEN.toLowerCase();
          const searchMovie =
            movieNameRu.includes(value.toLowerCase()) ||
            movieNameEN.includes(value.toLowerCase());
          return searchMovie;
        }
      }
    return  movies.filter((movie) => search(movie));
  }
