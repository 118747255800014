class Api {
    constructor({ baseUrl, headers }) {
        this._baseUrl = baseUrl
        this._headers = headers
    }
    _checkResponse(res) {
        if (res.ok) {
            return res.json();
        }
        return Promise.reject(`Ошибка ${res.status}`)
    }

    getInitialFilms() {  // загрузка фильмов с сервера
        return fetch(`${this._baseUrl}beatfilm-movies`, { 
            headers: this._headers,
        })
            .then(this._checkResponse)
    }
}

export const moviesApi = new Api({
    baseUrl: 'https://api.nomoreparties.co/',
        headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    }
});
