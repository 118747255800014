import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';

import './Header.css';
import mainLogoPath from '../../images/main-logo.svg';
import Navigation from '../Navigation/Navigation';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import useWindowWidth from '../../hooks/useWindowWidth';

export default function Header() {
  const {
    isLoggedIn
  } = React.useContext(AppContext);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = React.useState(false);
  const { pathname } = useLocation();
  const width = useWindowWidth();

  function toggleButtonMenu() {
    setIsBurgerMenuOpen(!isBurgerMenuOpen);
  }
  if (
    pathname === '/movies' ||
    pathname === '/saved-movies' ||
    pathname === '/' ||
    pathname === '/profile'
  ) {
    return (
      <header
        className={`header ${pathname === '/' ? 'header_placeMain' : ''}`}
      >
        <NavLink to='/' className='link header__logo'>
          <img className='logo' src={mainLogoPath} alt='Логотип' />
        </NavLink>
        {((width > 900) || !isLoggedIn) ? (
          <Navigation isBurgerMenuOpen={isBurgerMenuOpen} />
        ) : (
          <>
            <BurgerMenu
              isBurgerMenuOpen={isBurgerMenuOpen}
              toggleButtonMenu={toggleButtonMenu}
            />
          </>
        )}
      </header>
    );
  }
}
