import React from 'react';

import SearchForm from '../SearchForm/SearchForm';
import MoviesCardList from '../MoviesCardList/MoviesCardList';
import Preloader from '../Preloader/Preloader';
import { AppContext } from '../../contexts/AppContext';
import { SHORT_MOVIES } from '../../utils/constants';
import { mainApi } from '../../utils/MainApi';

import filterMovies from '../../utils/filterMovies';

import './SavedMovies.css';

export default function SavedMovies() {
  const [isStateSearch, setIsStateSearch] = React.useState(false); // резуьтат поиска true - найден фильм(ы) true - Был посик
  console.log()
  const {
    isPreloader,
    setPreloader,
    isSavedMovies,
    setIsSavedMovies,
    isShowedSavedMovies,
    setIsShowedSavedMovies,
  } = React.useContext(AppContext);

  const checkShortMovie = function (movie) {
    return movie.duration <= SHORT_MOVIES;
  };

  const pathname = window.location.pathname;
  const pathSavedMovies = pathname.includes('saved-movies');

  React.useEffect(() => {
    if (localStorage.getItem('savedMovies')) {
      setIsSavedMovies(JSON.parse(localStorage.getItem('savedMovies')));
      setIsShowedSavedMovies(JSON.parse(localStorage.getItem('savedMovies')));
    } else {
      setPreloader(true);
      mainApi
        .getSavedCard()
        .then((res) => {
          setIsSavedMovies(res);
          setIsShowedSavedMovies(res);
          localStorage.setItem('savedMovies', JSON.stringify(res));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setPreloader(false);
        });
    }
  }, [setIsSavedMovies, setIsShowedSavedMovies, setPreloader]);
  // сбрасываем состояние поиска
  React.useEffect(() => {
    setIsStateSearch(false)
  }, [pathSavedMovies])

  function handleSearch(value) {
    setIsStateSearch(true)
    setPreloader(true);
    const checkboxShortMovie = JSON.parse(
      localStorage.getItem('checkboxShortSavedMovie') || false
    );

    if (checkboxShortMovie) {
      const shortMovies = isSavedMovies.filter((movie) =>
        checkShortMovie(movie)
      );
      const searchMovies = filterMovies(shortMovies, value);
      setIsShowedSavedMovies(searchMovies);
    } else {
      const searchMovies = filterMovies(isSavedMovies, value);
      setIsShowedSavedMovies(searchMovies);
    }
    setPreloader(false);
  }

  return (
    <>
      <main className='savedMovies'>
        <SearchForm onSearch={handleSearch} />
        <Preloader isLoading={isPreloader} />
        {isStateSearch ? (
          isShowedSavedMovies.length > 0 ? (
            <MoviesCardList cards={isShowedSavedMovies} />
          ) : (
            <p className='movies__search-error-text'>Ничего не найдено</p>
          )
        ) : <MoviesCardList cards={isShowedSavedMovies} />}
      </main>
    </>
  );
}
