const LOGO_PATCH = '/images/logo.svg';
// для кнопки еще
const STEP_ADD_BUTTON_MOBILE = 2;
const STEP_ADD_BUTTON_TABLET = 3;
const STEP_ADD_BUTTON_DESKTOP = 4;
const LOAD_ADD_BUTTON_MOBILE = 5;
const LOAD_ADD_BUTTON_SMALL_TABLET = 8;
const LOAD_ADD_BUTTON_TABLET = 15;
const LOAD_ADD_BUTTON_DESKTOP = 16;
// для чекбокса
const SHORT_MOVIES = 40;

module.exports = {
  LOGO_PATCH,
  STEP_ADD_BUTTON_MOBILE,
  STEP_ADD_BUTTON_TABLET,
  STEP_ADD_BUTTON_DESKTOP,
  LOAD_ADD_BUTTON_MOBILE,
  LOAD_ADD_BUTTON_SMALL_TABLET,
  LOAD_ADD_BUTTON_TABLET,
  LOAD_ADD_BUTTON_DESKTOP,
  SHORT_MOVIES
};
