import './Portfolio.css';

export default function Portfolio() {
  return (
    <section id='portfolio' className='portfolio'>
      <h3 className='portfolio__title'>Портфолио</h3>
      <ul className='portfolio__container'>
        <li className='portfolio__container-link'>
          <a
            className='portfolio__link link'
            href='https://vk.ru'
            target='_blank'
            rel='noreferrer'
          >
            Статичный сайт
            <p className='portfolio__text'>↗</p>
          </a>
        </li>
        <li className='portfolio__container-link'>
          <a
            className='portfolio__link link'
            href='https://vk.ru'
            target='_blank'
            rel='noreferrer'
          >
            Адаптивный сайт
            <p className='portfolio__text'>↗</p>
          </a>
        </li>
        <li className='portfolio__container-link'>
          <a
            className='portfolio__link link'
            href='https://vk.ru'
            target='_blank'
            rel='noreferrer'
          >
            Одностраничное приложение
            <p className='portfolio__text'>↗</p>
          </a>
        </li>
      </ul>
    </section>
  );
}
