class Api {
  constructor({ baseUrl, headers }) {
    this._baseUrl = baseUrl;
    this._headers = headers;
  }
  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(`Ошибка ${res.json()}`);
  }

  checkAuthorization() {
    // проверка авторизации пользователя при заходе на сайт
    return fetch(`${this._baseUrl}`, {
      headers: this._headers,
      credentials: 'include',
    }).then(this._checkResponse);
  }

  signup(obj) {
    return fetch(`${this._baseUrl}signup`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({
        name: obj.name,
        password: obj.password,
        email: obj.email,
      }),
      credentials: 'include',
    }).then((res) => {
      return res.ok
        ? res.json()
        : res.json().then((err) => Promise.reject(err));
    });
  }

  signin(obj) {
    return fetch(`${this._baseUrl}signin`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({
        password: obj.password,
        email: obj.email,
      }),
      credentials: 'include',
    }).then((res) => {
      return res.ok
        ? res.json()
        : res.json().then((err) => Promise.reject(err));
    });
  }

  logout() {
    return fetch(`${this._baseUrl}logout`, {
      headers: this._headers,
      credentials: 'include',
    }).then(this._checkResponse);
  }

  updateProfile(obj) {
    return fetch(`${this._baseUrl}users/me`, {
      method: 'PATCH',
      headers: this._headers,
      body: JSON.stringify({
        name: obj.name,
        email: obj.email,
      }),
      credentials: 'include',
    }).then((res) => {
      return res.ok
        ? res.json()
        : res.json().then((err) => Promise.reject(err));
    });
  }

  getUser() {
    //загрузка данных о пользователе с сервера
    return fetch(`${this._baseUrl}users/me`, {
      headers: this._headers,
      credentials: 'include',
    }).then(this._checkResponse);
  }

  setLikeMovie(obj) {
    return fetch(`${this._baseUrl}movies`, {
      method: 'POST',
      headers: this._headers,
      body: JSON.stringify({
        country: obj.country,
        director: obj.director,
        duration: obj.duration,
        year: obj.year,
        description: obj.description,
        image: obj.image,
        trailerLink: obj.trailerLink,
        thumbnail: obj.thumbnail,
        movieId: obj.movieId,
        nameRU: obj.nameRU,
        nameEN: obj.nameEN,
      }),
      credentials: 'include',
    }).then(this._checkResponse);
  }

  delLikeMovie(idMovie) {
    return fetch(`${this._baseUrl}movies/${idMovie}`, {
      method: 'DELETE',
      headers: this._headers,
      credentials: 'include',
    }).then(this._checkResponse);
  }

  getSavedCard() {
    //загрузка данных о пользователе с сервера
    return fetch(`${this._baseUrl}movies`, {
      headers: this._headers,
      credentials: 'include',
    }).then(this._checkResponse);
  }
}

export const mainApi = new Api({
  baseUrl: `${window.location.protocol}${
    process.env.REACT_APP_API_URL || '//localhost:3002'
  }/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});
