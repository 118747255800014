import React from 'react';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import useWindowWidth from '../../hooks/useWindowWidth';

import './Navigation.css';
import iconProfile from '../../images/icon_profile.svg';

export default function Navigation({ isBurgerMenuOpen }) {
  const { isLoggedIn } = React.useContext(AppContext)
  const width = useWindowWidth();


  if (!isLoggedIn) {
    return (
        <nav className='navigation'> 
          <NavLink to='/signup' className='navigation__link-signup link'>
            Регистрация
          </NavLink>
          <NavLink to='/signin' className='navigation__link-ignin link'>
            Войти
          </NavLink>
        </nav>
    )
  }
  else {
    return (
      <div
        className={`navigation ${width < 900 ? 'navigation__disable' : ''} ${isBurgerMenuOpen ? 'navigation__burgerMenu' : ''
          }`}
      >
        <nav className='navigation__link'>
          <NavLink
            to='/'
            className='navigation__link-text navigation__burger-link link'
          >
            Главная
          </NavLink>
          <NavLink
            to='/movies'
            className={({ isActive }) =>
              'navigation__link-text link' +
              (isActive ? ' navigation__link-text_active' : '')
            }
          >
            Фильмы
          </NavLink>
          <NavLink
            to='/saved-movies'
            className={({ isActive }) =>
              'navigation__link-text link' +
              (isActive ? ' navigation__link-text_active' : '')
            }
          >
            Сохраненные фильмы
          </NavLink>
        </nav>
        <button className='navigation__profile-button'>
          <img
            className='navigation__profile-icon'
            src={iconProfile}
            alt='Логотип'
          />
          <NavLink to='/profile' className='navigation__profile-text link'>
            Аккаунт
          </NavLink>
        </button>
      </div>
    );
  }

}
