import { useLocation } from 'react-router-dom';

import './Footer.css';

export default function Footer() {

  const { pathname } = useLocation()
  if((pathname === '/movies') || (pathname === '/saved-movies') || (pathname === '/'))
  {
    return (
        <footer className="footer">
            <p className='footer__title'>Учебный проект Яндекс.Практикум х BeatFilm.</p>
            <div className='footer__container'>
                <p className="footer__copyright">&copy; {new Date().getFullYear()}</p>
                <ul className='footer__menu'>
                    <li className='footer__link'><a className='link' href="https://praktikum.yandex.ru" target="_blank" rel="noreferrer">Яндекс.Практикум</a></li>
                    <li className='footer__link'><a className='link' href="https://github.com" target="_blank" rel="noreferrer">Github</a></li>
                    <li className='footer__link'><a className='link' href="https://vk.ru" target="_blank" rel="noreferrer">Vk</a></li>
                </ul>
            </div>
        </footer>
    )
  }
    
}