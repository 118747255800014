import React from 'react';
import { AppContext } from '../../contexts/AppContext';
import useWindowWidth from '../../hooks/useWindowWidth';
import { getLoad } from '../../utils/getLoadAndStep';

import './SearchForm.css';
import FilterCheckbox from '../FilterCheckbox/FilterCheckbox';

export default function SearchForm({ onSearch }) {
  const { inputSearch, setInputSearch, setVisibleCardsCount, inputSearchSaved, setInputSearchSaved } = React.useContext(AppContext);
  const [isValid, setIsValid] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('Фильм') //для вывода ошибки валидации
  const width = useWindowWidth();
  const pathname = window.location.pathname;
  const pathSavedMovies = pathname.includes('saved-movies');

  function handleSearchChange(evt) {
    if(!pathSavedMovies) {
      setInputSearch(evt.target.value);
    } else {
      setInputSearchSaved(evt.target.value);
    }

    setIsValid(evt.target.checkValidity());
  }

  React.useEffect(() => {
    if (!pathSavedMovies) {
      const input = localStorage.getItem('inputSearchMovies');
      if (input) {
        setInputSearch(input);
        setIsValid(true);
      }
    }
  }, [pathSavedMovies, setInputSearch]);

  function handleSearch(evt) {
    evt.preventDefault();
    setVisibleCardsCount(getLoad(width))
    if (isValid) {
      (!pathSavedMovies)
      ? onSearch(inputSearch)
      : onSearch(inputSearchSaved)
    } else {
      setErrorMessage('Нужно ввести ключевое слово')
    }
  }

  function handleSearchCheckbox() {
    if (isValid) {
      (!pathSavedMovies)
      ? onSearch(inputSearch)
      : onSearch(inputSearchSaved)
    } else {
      setErrorMessage('Нужно ввести ключевое слово')
    }
  }

  return (
    <div className='searchForm'>
      <form
        className='searchForm__container'
        name='searchForm'
        onSubmit={handleSearch}
        noValidate
      >
        <input
          required
          className='searchForm__input'
          type='text'
          name='filmSeach'
          onChange={handleSearchChange}
          defaultValue={!pathSavedMovies ? inputSearch : ''}
          placeholder={errorMessage}
          minLength={1}
        />
        <button type='submit' className='searchForm__submit link'>
          Найти
        </button>
      </form>
      <FilterCheckbox onClick={handleSearchCheckbox} />
    </div>
  );
}