import React from 'react';
import './MoviesCard.css';
import convertTimeHourAndMinute from '../../utils/convertTimeHourAndMinute';
import { mainApi } from '../../utils/MainApi';
import { AppContext } from '../../contexts/AppContext';
import isURL from 'validator/es/lib/isURL';

export default function MoviesCard({ card, pathSavedMovies }) {
  const {
    isSavedMovies,
    setIsSavedMovies,
    setPreloader,
    setIsShowedSavedMovies,
    isShowedSavedMovies,
  } = React.useContext(AppContext);
  const [isSaved, setIsSaved] = React.useState(false);

  React.useEffect(() => {
    const myCard = isSavedMovies.some(
      (saveCard) => saveCard.movieId === card.id
    );
    setIsSaved(myCard);
  }, [card, isSavedMovies]);

  const classNameButton = pathSavedMovies
    ? `moviesCard__button_type_page-savedMovies`
    : `moviesCard__button ${isSaved ? 'moviesCard__button_active' : ''}`;

  const handleLikeMovie = (evt) => {
    evt.stopPropagation();
    setPreloader(true);
    const sendObj = {
      country: card.country || 'Отсутствует',
      director: card.director || 'Отсутствует',
      duration: card.duration || 'Отсутствует',
      year: card.year || 'Отсутствует',
      description: card.description || 'Отсутствует',
      image: isURL(
        `https://api.nomoreparties.co${card.image.url}` ||
          'https://api.nomoreparties.co'
      )
        ? `https://api.nomoreparties.co${card.image.url}` ||
          'https://api.nomoreparties.co'
        : 'https://www.thenextevolution.com/wp-content/uploads/2015/05/free-hd-stock-videos.jpg',

      trailerLink: isURL(
        `https://api.nomoreparties.co/${card.trailerLink}` ||
          'https://api.nomoreparties.co'
      )
        ? `https://api.nomoreparties.co/${card.trailerLink}` ||
          'https://api.nomoreparties.co'
        : 'https://youtube.com',
      thumbnail: isURL(
        `https://api.nomoreparties.co${card.image.url}` ||
          'https://api.nomoreparties.co'
      )
        ? `https://api.nomoreparties.co${card.image.url}` ||
          'https://api.nomoreparties.co'
        : 'https://www.thenextevolution.com/wp-content/uploads/2015/05/free-hd-stock-videos.jpg',
      movieId: card.id,
      nameRU: card.nameRU,
      nameEN: card.nameEN,
    };

    if (isSaved || pathSavedMovies) {
      const { _id } = isSavedMovies.find(
        (saveCard) =>
          saveCard.movieId === (!pathSavedMovies ? card.id : card.movieId)
      );
      mainApi
        .delLikeMovie(_id)
        .then(() => {
          const newSavedMovies = isSavedMovies.filter(
            (saveCard) =>
              saveCard.movieId !== (!pathSavedMovies ? card.id : card.movieId)
          );
          const newShowedSavedMovies = isShowedSavedMovies.filter(
            (showedcard) => showedcard.movieId !== (!pathSavedMovies ? card.id : card.movieId)
          );
          setIsSavedMovies(newSavedMovies);
          setIsShowedSavedMovies(newShowedSavedMovies);
          localStorage.setItem('savedMovies', JSON.stringify(newSavedMovies));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setPreloader(false);
        });
      return;
    } else {
      mainApi
        .setLikeMovie(sendObj)
        .then((res) => {
          setIsSavedMovies([res, ...isSavedMovies]);
          localStorage.setItem(
            'savedMovies',
            JSON.stringify([res, ...isSavedMovies])
          );
          setIsShowedSavedMovies([res, ...isShowedSavedMovies]);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setPreloader(false);
        });
      return;
    }
  };

  return (
    <>
      <article className='moviesCard'>
        <a href={card.trailerLink} target='_blank' rel='noreferrer'>
          <img
            className='moviesCard__img'
            src={
              pathSavedMovies
                ? card.image
                : `https://api.nomoreparties.co${card.image.url}`
            }
            alt={card.nameRU}
          />
        </a>
        <div className='moviesCard__description'>
          <h2 className='moviesCard__caption'>{card.nameRU}</h2>
          <button
            type='button'
            onClick={handleLikeMovie}
            className={classNameButton}
          ></button>
        </div>
        <p className='moviesCard__duration'>
          {convertTimeHourAndMinute(card.duration)}
        </p>
      </article>
    </>
  );
}
