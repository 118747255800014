import Navigation from '../Navigation/Navigation';

import './BurgerMenu.css';

export default function BurgerMenu({ isBurgerMenuOpen, toggleButtonMenu }) {
  return (
    <>
      <button
        onClick={toggleButtonMenu}
        className={`burgerMenu ${isBurgerMenuOpen ? 'burgerMenu_open' : ''}`}
      ></button>
      <Navigation isBurgerMenuOpen={isBurgerMenuOpen} />
    </>
  );
}
