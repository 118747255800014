import { NavLink } from 'react-router-dom';
import mainLogoPath from '../../images/main-logo.svg';
import FormSignin from '../Form/FormSignin';

import './Signin.css';

export default function Signin() {
  
  
  return (
    <div className='signin'>
      <div className='signin__container'>
        <NavLink to='/' className='link header__logo'>
          <img className='logo' src={mainLogoPath} alt='Логотип' />
        </NavLink>
        <h1 className='signin__title'>Рады видеть!</h1>
        <FormSignin />
        <p className='signin__text link'>
          Еще не зарегистрированы?
          <NavLink className='signin__link link' to='/signup'>
            {' '}
            Регистрация{' '}
          </NavLink>
        </p>
      </div>
    </div>
  );
}
