import './AboutProject.css';

export default function AboutProject() {
  return (
    <section id='aboutProject' className='aboutProject'>
      <h2 className='aboutProject__title'>
        О проекте
      </h2>
      <div className='aboutProject__container'>
        <article className='aboutProject__article'>
          <h3 className='aboutProject__article-title'>Дипломный проект включал 5 этапов</h3>
          <p className='aboutProject__article-text'>
            Составление плана, работу над бэкендом, вёрстку, добавление
            функциональности и финальные доработки.
          </p>
        </article>
        <article className='aboutProject__article'>
          <h3 className='aboutProject__article-title'>На выполнение диплома ушло 5 недель</h3>
          <p className='aboutProject__article-text'>
            У каждого этапа был мягкий и жёсткий дедлайн, которые нужно было
            соблюдать, чтобы успешно защититься.
          </p>
        </article>
      </div>
      <div className='aboutProject__stage-container'>
        <p className='aboutProject__stage'>1 неделя</p>
        <p className='aboutProject__stage'>4 недели</p>
        <p className='aboutProject__stage-text'>Back-end</p>
        <p className='aboutProject__stage-text'>Front-end</p>
      </div>
    </section>
  );
}
