import './AboutMe.css';
import fotoPath from '../../images/aboutMe_foto.png';

export default function AboutMe() {
  return (
    <section id='aboutMe' className='aboutMe'>
      <h2 className='aboutMe__title'>Студент</h2>
      <div className='aboutMe__container'>
        <div className='aboutMe__container-info'>
          <div className='aboutMe__container-info-text'>
            <h3 className='aboutMe__name'>Виталий</h3>
            <p className='aboutMe__profession'>Фронтенд-разработчик, 30 лет</p>
            <p className='aboutMe__about'>
              Я родился и живу в Саратове, закончил факультет экономики СГУ. У
              меня есть жена и дочь. Я люблю слушать музыку, а ещё увлекаюсь
              бегом. Недавно начал кодить. С 2015 года работал в компании «СКБ
              Контур». После того, как прошёл курс по веб-разработке, начал
              заниматься фриланс-заказами и ушёл с постоянной работы.
            </p>
          </div>
          <nav className='aboutMe__nav'>
            <a
              className='aboutMe__link link'
              href='https://praktikum.yandex.ru'
              target='_blank'
              rel='noreferrer'
            >
              Facebook
            </a>
            <a
              className='aboutMe__link link'
              href='https://github.com'
              target='_blank'
              rel='noreferrer'
            >
              Github
            </a>
          </nav>
        </div>
        <img className='aboutMe__foto' src={fotoPath} alt='Фотография' />
      </div>
    </section>
  );
}
