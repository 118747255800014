import React from 'react';

import SearchForm from '../SearchForm/SearchForm';
import MoviesCardList from '../MoviesCardList/MoviesCardList';
import Preloader from '../Preloader/Preloader';
import { AppContext } from '../../contexts/AppContext';
import { SHORT_MOVIES } from '../../utils/constants';
import { moviesApi } from '../../utils/MoviesApi';

import filterMovies from '../../utils/filterMovies';
import './Movies.css';

export default function Movies() {
  const { isPreloader, setPreloader, inputSearch, isInitialMovies, setIsInitialMovies } = React.useContext(AppContext);
  const [isShowedMovies, setIsShowedMovies] = React.useState([]); // фильмы показанные
  const [isStateSearch, setIsStateSearch] = React.useState(true); // резуьтат поиска true - найден фильм(ы)
  const checkShortMovie = function (movie) {
    return movie.duration <= SHORT_MOVIES;
  };

  React.useEffect(() => {
    if (localStorage.getItem('initialMovies')) {
      setIsInitialMovies(JSON.parse(localStorage.getItem('initialMovies')));
    } else {
      setPreloader(true);
      moviesApi
        .getInitialFilms()
        .then((res) => {
          setIsInitialMovies(res);
          localStorage.setItem('initialMovies', JSON.stringify(res));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setPreloader(false);
        });
    }
  }, [setIsInitialMovies, setPreloader]);  //зависимости ? //setInputSearch

  React.useEffect(() => {
    if(inputSearch) {
      handleSearch(inputSearch)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleSearch(value) {
    setPreloader(true);
    const checkboxShortMovie = JSON.parse(
      localStorage.getItem('checkboxShortMovie') || false
    );

    if (checkboxShortMovie) {
      const shortMovies = isInitialMovies.filter((movie) =>
        checkShortMovie(movie)
      );
      const searchMovies = filterMovies(shortMovies, value);
      searchMovies.length === 0
        ? setIsStateSearch(false)
        : setIsStateSearch(true);
      setIsShowedMovies(searchMovies);
      localStorage.setItem('searchMovies', JSON.stringify(searchMovies));
      localStorage.setItem('inputSearchMovies', value);
    } else {
      const searchMovies = filterMovies(isInitialMovies, value);
      searchMovies.length === 0
        ? setIsStateSearch(false)
        : setIsStateSearch(true);
      setIsShowedMovies(searchMovies);
      localStorage.setItem('searchMovies', JSON.stringify(searchMovies));
      localStorage.setItem('inputSearchMovies', value);
    }
    setPreloader(false);
  }

  return (
    <main className='movies'>
      <SearchForm onSearch={handleSearch} />
      <Preloader isLoading={isPreloader} />
      {isStateSearch ? (
        <MoviesCardList cards={isShowedMovies} />
      ) : (
        <p className='movies__search-error-text'>Ничего не найдено</p>
      )}
    </main>
  );
}
