import React from 'react';
import { useNavigate } from 'react-router-dom';

import { mainApi } from '../../utils/MainApi';
import { useFormWithValidation } from '../../hooks/useForm';
import { AppContext } from '../../contexts/AppContext';

import './Form.css';

export default function FormSignup() {
  const { setIsLoggedIn } = React.useContext(AppContext);
  const [isLoading, setIsLoading] = React.useState(false); // состояние выполнение запроса
  const { values, handleChange, errors, isValid } = useFormWithValidation();
  const { name, password, email } = values;
  const [isErrorApi, setIsErrorApi] = React.useState('');

  let navigate = useNavigate(); // навигация в react v6

  function handleSubmit(evt) {
    evt.preventDefault();
    setIsLoading(true);
    mainApi
      .signup({
        name,
        password,
        email,
      })
      .then(() => {
        mainApi.signin({
          email,
          password,
        })
        .then(() => {
          setIsLoggedIn(true);
          navigate('/movies');
        })
      })
      .catch((err) => {
        setIsErrorApi(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <form
      noValidate
      className='form'
      name='form__signup'
      onSubmit={handleSubmit}
    >
      <label className='form__label'>
        Имя
        <input
          required
          onChange={handleChange}
          className='form__input'
          type='text'
          name='name'
          value={values.name || ''}
          minLength={2}
          maxLength={30}
          pattern='^[А-Яа-яЁёa-zA-Z\-\s]+$'
        />
      </label>
      <p className='form__text-error'>{errors.name || ''}</p>
      <label className='form__label'>
        E-mail
        <input
          required
          onChange={handleChange}
          className='form__input'
          type='email'
          name='email'
          pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
          value={values.email || ''}
        />
      </label>
      <p className='form__text-error'>{errors.email || ''}</p>
      <label className='form__label'>
        Пароль
        <input
          required
          onChange={handleChange}
          className='form__input form__input_password'
          type='password'
          name='password'
          value={values.password || ''}
        />
      </label>
      <p className='form__text-error'>{errors.password || ''}</p>
      <p className='form__text-api-error form__text-api-error_signup'>
        {isErrorApi}
      </p>
      <button
        type='submit'
        disabled={!isValid  || isLoading}
        className='form__button-submit link'
      >
        {!isLoading ? 'Зарегестрироваться' : 'Сохранение...'}
      </button>
    </form>
  );
}
