import {
  STEP_ADD_BUTTON_MOBILE,
  STEP_ADD_BUTTON_TABLET,
  STEP_ADD_BUTTON_DESKTOP,
  LOAD_ADD_BUTTON_MOBILE,
  LOAD_ADD_BUTTON_SMALL_TABLET,
  LOAD_ADD_BUTTON_TABLET,
  LOAD_ADD_BUTTON_DESKTOP,
} from '../utils/constants';

export function getLoad(width) {
  if (width <= 767) {
    return LOAD_ADD_BUTTON_MOBILE;
  } else if ((width > 767) && (width <= 989)) {
    return LOAD_ADD_BUTTON_SMALL_TABLET;
  } else if ((width > 989) && (width <= 1296)) {
    return LOAD_ADD_BUTTON_TABLET;
  } else {
    return LOAD_ADD_BUTTON_DESKTOP;
  }
}

export function getStep(width) {
  if (width <= 989) {
    return STEP_ADD_BUTTON_MOBILE;
  } else if ((width > 989) && (width <= 1296)) {
    return STEP_ADD_BUTTON_TABLET;
  } else {
    return STEP_ADD_BUTTON_DESKTOP;
  }
}
