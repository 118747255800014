
import React, { useState } from 'react';
import { Route, Routes  } from 'react-router-dom';
import ProtectedRoute from '../ProtectedRoute';
import HiddenRoute from '../HiddenRoute';

import Header from '../Header/Header';
import Main from '../Main/Main';
import Footer from '../Footer/Footer';
import Signup from '../Signup/Signup';
import Signin from '../Signin/Signin';
import Profile from '../Profile/Profile';
import NotFound from '../NotFound/NotFound';
import Movies from '../Movies/Movies';
import SavedMovies from '../SavedMovies/SavedMovies';
import { mainApi } from '../../utils/MainApi';
import { CurrentUserContext } from '../../contexts/CurrentUserContext';
import { AppContext } from '../../contexts/AppContext';

import useWindowWidth from '../../hooks/useWindowWidth';
import { getLoad } from '../../utils/getLoadAndStep';

import './App.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false); // состояние авторизации
  const [isCurrentUser, setCurrentUser] = React.useState({}); // данные текущего пользователя
  const [isInitialMovies, setIsInitialMovies] = React.useState([]); // фильмы из API или localStorage
  const width = useWindowWidth();
  const [visibleCardsCount, setVisibleCardsCount] = useState(getLoad(width));
  const [isSavedMovies, setIsSavedMovies] = React.useState([]); // сохр. фильмы
  const [isShowedSavedMovies, setIsShowedSavedMovies] = React.useState([]); // фильмы показанные
  const [inputSearch, setInputSearch] = React.useState(); // инпут для movies
  const [inputSearchSaved, setInputSearchSaved] = React.useState(); // инпут для saved-movies
  const [isPreloader, setPreloader] = React.useState(); // состояние прелоадера

  function tokenCheck() {
    mainApi
      .checkAuthorization()
      .then(() => {
        setIsLoggedIn(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  React.useEffect(() => {
    tokenCheck();
    if (isLoggedIn) {
      mainApi
        .getUser()
        .then((user) => {
          setCurrentUser(user);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    if (localStorage.getItem('initialMovies')) {
      setIsInitialMovies(JSON.parse(localStorage.getItem('initialMovies')));
    }
    if (localStorage.getItem('inputSearchMovies')) {
      setInputSearch(localStorage.getItem('inputSearchMovies'));
    }
  }, [isLoggedIn]);

  return (
    <AppContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        isInitialMovies,
        setIsInitialMovies,
        isSavedMovies,
        setIsSavedMovies,
        isShowedSavedMovies,
        setIsShowedSavedMovies,
        inputSearch,
        setInputSearch,
        isPreloader,
        setPreloader,
        visibleCardsCount,
        setVisibleCardsCount,
        inputSearchSaved,
        setInputSearchSaved,
      }}
    >
      <CurrentUserContext.Provider value={{ isCurrentUser, setCurrentUser }}>
        <div className='page'>
          <div className='root'>
            <Header />
            <Routes>
              <Route path='/' element={<Main />} />
              <Route
                path='signup/*'
                element={
                  <HiddenRoute isLoggedIn={isLoggedIn}>
                    <Signup />
                  </HiddenRoute>
                }
              />
              <Route
                path='signin/*'
                element={
                  <HiddenRoute isLoggedIn={isLoggedIn}>
                    <Signin tokenCheck={tokenCheck} />
                  </HiddenRoute>
                }
              />
              <Route
                path='profile/*'
                element={
                  <ProtectedRoute isLoggedIn={isLoggedIn}>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path='movies/*'
                element={
                  <ProtectedRoute isLoggedIn={isLoggedIn}>
                    <Movies />
                  </ProtectedRoute>
                }
              />
              <Route
                path='saved-movies/*'
                element={
                  <ProtectedRoute isLoggedIn={isLoggedIn}>
                    <SavedMovies />
                  </ProtectedRoute>
                }
              />
              <Route path='*' element={<NotFound />} />
            </Routes>
            <footer>
              <Footer />
            </footer>
          </div>
        </div>
      </CurrentUserContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
