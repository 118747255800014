import React from 'react';

import { NavLink } from 'react-router-dom';
import mainLogoPath from '../../images/main-logo.svg';
import FormSignup from '../Form/FormSignup';

import './Signup.css';

export default function Signup() {

  return (
    <div className='signup'>
      <div className='signup__container'>
        <NavLink to='/' className='link header__logo'>
          <img className='logo' src={mainLogoPath} alt='Логотип' />
        </NavLink>
        <h1 className='signup__title'>Добро пожаловать!</h1>
        <FormSignup />
        <p className='signup__text link'>
          Уже зарегистрированы?
          <NavLink className='signup__link link' to='/signin'>
            {' '}
            Войти{' '}
          </NavLink>
        </p>
      </div>
    </div>
  );
}
