import React, { useState } from 'react';
import { AppContext } from '../../contexts/AppContext';

import MoviesCard from '../MoviesCard/MoviesCard';
import useWindowWidth from '../../hooks/useWindowWidth';
import { getStep } from '../../utils/getLoadAndStep';

import './MoviesCardList.css';

export default function MoviesCardList({ cards }) {
  const { visibleCardsCount, setVisibleCardsCount } = React.useContext(AppContext);
  const pathname = window.location.pathname;
  // для проверки отображения кнопки добавления на стр. сохр. фильмов
  const pathSavedMovies = pathname.includes('saved-movies');
  const width = useWindowWidth();
  const [visibleCards, setVisibleCards] = useState([])

  function addButton() {
    setVisibleCardsCount((prevCount) => prevCount + getStep(width));
  };

  React.useEffect(() => {
    if(cards.length) {
      const res = cards.slice(0, visibleCardsCount);
      setVisibleCards(res)
    }
  }, [cards, visibleCardsCount])

  /*
  try {
    set(JSON.parse())
  }
  catch (err) {
    dsfsd
  }
  */
  
  return (
    <section className='moviesCardList'>
      <div className='moviesCardList__container'>
        {cards.length !== 0 ? (
          visibleCards.map((card) => (
          <MoviesCard key={(pathSavedMovies) ? card.movieId : card.id} card={card} pathSavedMovies={pathSavedMovies} />
          ))
        ) :
        (
          ''
        )
        }
      </div>
      {cards.length !== visibleCards.length ? (
        <button
          type='button'
          onClick={addButton}
          className={`moviesCardList__addCard link ${
            pathSavedMovies ? 'moviesCardList__addCard_disable' : ''
          }`}
        >
          Еще
        </button>
      ) : (
        ''
      )}
    </section>
  );
}
