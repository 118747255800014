import React from 'react';
import './FilterCheckbox.css';

export default function FilterCheckbox({onClick}) {
  const checkboxShortMovie = JSON.parse(
    localStorage.getItem('checkboxShortMovie')
  );
  const pathname = window.location.pathname;
  const pathSavedMovies = pathname.includes('saved-movies');

  function onChangeCheckbox(evt) {
    if (!pathSavedMovies) {
      localStorage.setItem('checkboxShortMovie', JSON.stringify(evt.target.checked))
      onClick();
    } else {
      localStorage.setItem('checkboxShortSavedMovie', JSON.stringify(evt.target.checked))
      onClick();
    }
  }

  return (
    <div className='checkbox'>
      <label className='checkbox__label'>
        <input
          className='checkbox__input'
          type='checkbox'
          name='short-film'
          defaultChecked={!pathSavedMovies ? checkboxShortMovie : false}
          onChange={onChangeCheckbox}
        />
        <span className='checkbox__switch'></span>
        Короткометражки
      </label>
    </div>
  );
}
